import Vue from 'vue';
import VClamp from "vue-clamp";

export default Vue.extend({
  name: 'QuatersCarousel',
  components: {
    VClamp
  },
  props: {
    quaterns: {
      type: [Object, Array],
      default: () => {},
      required: true
    },
    isQuatern: {
      type: Boolean,
      default: true
    }
  }
});
